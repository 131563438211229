import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";
import BlogPost from "../components/blog-post";

import { useI18next } from "gatsby-plugin-react-i18next";

const Post = ({ data }) => {
  const { language } = useI18next();
  return (
    <Layout>
      <BlogPost data={data} language={language} />
      <SecondaryClouds />
    </Layout>
  );
};
export default Post;

export const query = graphql`
  query Post($id: String!, $language: String!) {
    post: allDatoCmsPost(filter: { id: { eq: $id } }) {
      nodes {
        title
        slug
        date(formatString: "DD.MM.YY")
        excerpt
        body {
          value
          links
          blocks {
            __typename
            ... on DatoCmsBlurredImage {
              id: originalId
              url
              image {
                url(imgixParams: { auto: "enhance", q: 100, w: "800" })
                title
                alt
                width
                height
              }
            }
            ... on DatoCmsBlogImage {
              id: originalId
              url
              image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
                title
                width
                height
              }
            }
          }
        }
        heroImage {
          url(imgixParams: { auto: "enhance", q: 100, w: "1240" })
          alt
        }
        id
        seo {
          title
          description
          image {
            url
          }
        }
        author {
          name
        }
        tag {
          tagName
          slug
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
    blog: datoCmsBlog(locale: { eq: $language }) {
      filters
      pageTitle
      cta
      related
      seeAll
      shareCta
      socialSharing {
        socialMediaShare
      }
    }
    morePosts: allDatoCmsPost(
      sort: { fields: date, order: DESC }
      limit: 4
      filter: { id: { ne: $id } }
    ) {
      nodes {
        id
        excerpt
        slug
        title
        date
        heroImage {
          url(imgixParams: { auto: "enhance", q: 100, w: "600" })
          alt
          width
          height
        }
      }
    }
  }
`;
