import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { StructuredText } from 'react-datocms';
import BlurredImage from '../components/blurred-image';
import BlurredImageCaption from '../components/blurred-image-caption';
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { withAssetPrefix } from 'gatsby';
import BlogImage from './blog-image';

const BlogPost = ({ data, language}) => {
    const socialSharingLinks = {
        facebook: 'https://www.facebook.com/sharer.php?u=',
        twitter: 'https://twitter.com/share?text=',
        linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url='
    }
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
            siteMetadata {
                siteUrl
                }
        }
    }
    `,
    );
    return (
        <main className='blog-post'>
            {data.post.nodes.map(post => (
                <div className='blog-post-layout'>
                    <HelmetDatoCms 
                        seo={post.seoMetaTags}
                    />
                    <div className='hero-image'>
                        <BlurredImage className='hero-image' image={post.heroImage} />
                    </div>
                    <div className='blog-post-inner'>
                        <h1>{post.title}</h1>
                        <div className='blog-post-info'>
                            <span className="blog-tag"><span class="btn-text">{post.tag.tagName}</span></span>
                            <span className='date-tag'><span class="btn-text">{post.date}</span></span>
                            <h6>{post.author.name}</h6>
                        </div>
                        <div class="sticky-container">
                            <div className='blog-post-share-container'>
                                <h6>{data.blog.shareCta}</h6>
                                <div className='blog-post-share'>
                                    {data.blog.socialSharing.map(share => {
                                        const currentPage = `${site.siteMetadata.siteUrl}/blog/${post.slug}`
                                        if (share.socialMediaShare === 'twitter') {
                                            return <a target="_blank" rel="noreferrer" className='social-link' href={`${socialSharingLinks.twitter}${post.title}&url=${currentPage}`}>
                                                <img src={withAssetPrefix('/images/'+ share.socialMediaShare +'.svg')} alt="logo" className='logo' loading='lazy'/>
                                            </a>
                                        }
                                        if (share.socialMediaShare === 'facebook') {
                                            return <a target="_blank" rel="noreferrer" className='social-link' href={`${socialSharingLinks.facebook}${currentPage}`}>
                                                <img src={withAssetPrefix('/images/'+ share.socialMediaShare +'.svg')} alt="logo" className='logo' loading='lazy'/>
                                            </a>
                                        }
                                        if (share.socialMediaShare === 'linkedin') {
                                            return <a target="_blank" rel="noreferrer" className='social-link' href={`${socialSharingLinks.linkedin}${currentPage}`}>
                                                <img src={withAssetPrefix('/images/'+ share.socialMediaShare +'.svg')} alt="logo" className='logo' loading='lazy'/>
                                            </a>
                                        }
                                        if (share.socialMediaShare === 'pinterest') {
                                            return <a target="_blank" rel="noreferrer" className='social-link' href={`https://pinterest.com/pin/create/button/?url=${currentPage}&media=${post.heroImage.url}&description=${post.title}`}>
                                                <img src={withAssetPrefix('/images/'+ share.socialMediaShare +'.svg')} alt="logo" className='logo' loading='lazy'/>
                                            </a>
                                        }
                                        return <></>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='blog-post-content'>
                            <p className='lede'>{post.excerpt}</p>
                            <StructuredText 
                            data={post.body}
                            renderBlock={({ record }) => {
                            if (record.__typename === "DatoCmsBlurredImage") {
                                return <BlurredImageCaption image={record.image} url={record.url} language={language}/>;
                            }

                            if (record.__typename === "DatoCmsBlogImage") {
                                return <BlogImage image={record.image} url={record.url} language={language}/>;
                            }
                            return (
                                <p>Woops! Something went wrong...</p>
                            );
                            }}
                            />
                        </div>
                    </div>
                </div>
            ))}

            <div className='blog-post-related'>
                <div className='blog-post-related-title'>
                    <h3 className='prewrap'>{data.blog.related}</h3>
                    <Link to='/blog' language={language} className="tertiary-link"><h6>{data.blog.seeAll}</h6></Link>
                </div>
                <div className='blog-posts-grid'>
                    {data.morePosts.nodes.map(post => (
                        <Link className="blog-post" key={post.id} to={`/blog/${post.slug}`}>
                            <BlurredImage image={post.heroImage} />
                            <h5>{post.title}</h5>
                            <p>{post.excerpt}</p>
                            <span className="tertiary-link">{data.blog.cta}</span>
                        </Link>
                    ))}
                </div>
            </div>
        </main>
    )
}
export default BlogPost