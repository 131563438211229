import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import ChildLink from "./child-link";

const BlogImage = ({ image, url, language }) => {
  if (url !== "") {
    return (
      <ChildLink href={url} language={language} classes="blog-image">
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt}
          height={image.height}
          width={image.width}
        />
        <span class="image-caption">{image.title}</span>
      </ChildLink>
    );
  } else {
    return (
      <div className="blog-image">
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt}
          height={image.height}
          width={image.width}
        />
        <span class="image-caption">{image.title}</span>
      </div>
    );
  }
};
export default BlogImage;
